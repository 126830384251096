import {React, useEffect} from 'react';
import {parisSportsImage} from '../../assets';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as GreenPageAfterSVG } from '../../assets/SVG/GreenPageAfter.svg';

const GreenPageAfter = ({ feedback, onNext, isTrue }) => {
  const navigate = useNavigate();
    useEffect(() => {
      const homeWiseathlete = document.getElementById('home_wiseathlete');
      const correctBoxGreen = document.getElementById('correct-box-green');
      const tryAgainBoxGreen = document.getElementById('Try-again-box-green');
      const photoBoxGreen = document.getElementById('photo-box-green');
      const tryAgainBoxGreentext = document.getElementById('Try-again-box-green-text');
      const correctBoxGreentext = document.getElementById('correct-box-green-text');
      if (homeWiseathlete && correctBoxGreen && tryAgainBoxGreen && photoBoxGreen)
      {  
        homeWiseathlete.addEventListener('mouseover', () => {
            homeWiseathlete.style.cursor = 'pointer';
        });
  
        homeWiseathlete.addEventListener('mouseout', () => {
            homeWiseathlete.style.cursor = 'default';
        });
  
        homeWiseathlete.addEventListener('click', () => {
          navigate('/');
        });

  
        correctBoxGreen.addEventListener('mouseover', () => {
            correctBoxGreen.style.cursor = 'pointer';
        });
  
        correctBoxGreen.addEventListener('mouseout', () => {
            correctBoxGreen.style.cursor = 'default';
        });
        correctBoxGreen.addEventListener('click', onNext);
        
  
        tryAgainBoxGreen.addEventListener('mouseover', () => {
            tryAgainBoxGreen.style.cursor = 'pointer';
        });
  
        tryAgainBoxGreen.addEventListener('mouseout', () => {
            tryAgainBoxGreen.style.cursor = 'default';
        });
        tryAgainBoxGreen.addEventListener('click',onNext);
        

        photoBoxGreen.setAttribute('href',parisSportsImage)


        if(isTrue) {
          tryAgainBoxGreen.style.display = "none";
            correctBoxGreentext.textContent = "Next";
            
        }
        else {
          correctBoxGreen.style.display = "none";
          tryAgainBoxGreentext.textContent = "Try Again";
        }
      }
     
    },[]);
  return (
    <div className="App-header-AfterGreen">
      <GreenPageAfterSVG/>
      <div className="question-discription-green-div">
          <p className="question-discription-green">{feedback}</p>
      </div>
    </div>
  );
}

export default GreenPageAfter;