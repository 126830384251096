import {React, useEffect} from 'react';
import {parisSportsImage} from '../../assets';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RedPageAfterSVG } from '../../assets/SVG/RedPageAfter.svg';

const RedPageAfter = ({ feedback, onNext, isTrue }) => {
  const navigate = useNavigate();
    useEffect(() => {
      const homeWiseathlete = document.getElementById('home_wiseathlete');
      const correctBoxRed = document.getElementById('correct-box-red');
      const tryAgainBoxRed = document.getElementById('Try-again-box-red');
      const photoBoxRed = document.getElementById('photo-box-red');
      const tryAgainBoxRedtext = document.getElementById('Try-again-box-red-text');
      const correctBoxRedtext = document.getElementById('correct-box-red-text');
      if (homeWiseathlete && correctBoxRed && tryAgainBoxRed && photoBoxRed)
      {  
        homeWiseathlete.addEventListener('mouseover', () => {
            homeWiseathlete.style.cursor = 'pointer';
        });
  
        homeWiseathlete.addEventListener('mouseout', () => {
            homeWiseathlete.style.cursor = 'default';
        });
  
        homeWiseathlete.addEventListener('click', () => {
          navigate('/');
        });

  
        correctBoxRed.addEventListener('mouseover', () => {
            correctBoxRed.style.cursor = 'pointer';
        });
  
        correctBoxRed.addEventListener('mouseout', () => {
            correctBoxRed.style.cursor = 'default';
        });
        correctBoxRed.addEventListener('click', onNext);
        
  
        tryAgainBoxRed.addEventListener('mouseover', () => {
            tryAgainBoxRed.style.cursor = 'pointer';
        });
  
        tryAgainBoxRed.addEventListener('mouseout', () => {
            tryAgainBoxRed.style.cursor = 'default';
        });
        tryAgainBoxRed.addEventListener('click',onNext);
        

        photoBoxRed.setAttribute('href',parisSportsImage)


        if(isTrue) {
          tryAgainBoxRed.style.display = "none";
            correctBoxRedtext.textContent = "Next";
            
        }
        else {
          correctBoxRed.style.display = "none";
          tryAgainBoxRedtext.textContent = "Try Again";
        }
      }
     
    },[]);
  return (
    <div className="App-header-AfterRed">
      <RedPageAfterSVG/>
      <div className="question-discription-red-div">
          <p className="question-discription-red">{feedback}</p>
      </div>
    </div>
  );
}

export default RedPageAfter;