import {React, useEffect} from 'react';
import {parisSportsImage} from '../../assets';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as YellowPageAfterSVG } from '../../assets/SVG/YellowPageAfter.svg';

const YellowPageAfter = ({ feedback, onNext, isTrue }) => {
  const navigate = useNavigate();
    useEffect(() => {
      const homeWiseathlete = document.getElementById('home_wiseathlete');
      const correctBoxYellow = document.getElementById('correct-box-yellow');
      const tryAgainBoxYellow = document.getElementById('Try-again-box-yellow');
      const photoBoxYellow = document.getElementById('photo-box-yellow');
      const tryAgainBoxYellowtext = document.getElementById('Try-again-box-yellow-text');
      const correctBoxYellowtext = document.getElementById('correct-box-yellow-text');
      if (homeWiseathlete && correctBoxYellow && tryAgainBoxYellow && photoBoxYellow)
      {  
        homeWiseathlete.addEventListener('mouseover', () => {
            homeWiseathlete.style.cursor = 'pointer';
        });
  
        homeWiseathlete.addEventListener('mouseout', () => {
            homeWiseathlete.style.cursor = 'default';
        });
  
        homeWiseathlete.addEventListener('click', () => {
          navigate('/');
        });

  
        correctBoxYellow.addEventListener('mouseover', () => {
            correctBoxYellow.style.cursor = 'pointer';
        });
  
        correctBoxYellow.addEventListener('mouseout', () => {
            correctBoxYellow.style.cursor = 'default';
        });
        correctBoxYellow.addEventListener('click', onNext);
        
  
        tryAgainBoxYellow.addEventListener('mouseover', () => {
            tryAgainBoxYellow.style.cursor = 'pointer';
        });
  
        tryAgainBoxYellow.addEventListener('mouseout', () => {
            tryAgainBoxYellow.style.cursor = 'default';
        });
        tryAgainBoxYellow.addEventListener('click',onNext);
        

        photoBoxYellow.setAttribute('href',parisSportsImage)


        if(isTrue) {
          tryAgainBoxYellow.style.display = "none";
            correctBoxYellowtext.textContent = "Next";
            
        }
        else {
          correctBoxYellow.style.display = "none";
          tryAgainBoxYellowtext.textContent = "Try Again";
        }
      }
     
    },[]);
  return (
    <div className="App-header-AfterYellow">
      <YellowPageAfterSVG/>
      <div className="question-discription-yellow-div">
          <p className="question-discription-yellow">{feedback}</p>
      </div>
    </div>
  );
}

export default YellowPageAfter;