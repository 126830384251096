import {React, useEffect} from 'react';
import {parisSportsImage} from '../../assets';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BluePageAfterSVG } from '../../assets/SVG/BluePageAfter.svg';

const BluePageAfter = ({ feedback, onNext, isTrue }) => {
  const navigate = useNavigate();
    useEffect(() => {
      const homeWiseathlete = document.getElementById('home_wiseathlete');
      const correctBoxBlue = document.getElementById('correct-box-blue');
      const tryAgainBoxBlue = document.getElementById('Try-again-box-blue');
      const photoBoxBlue = document.getElementById('photo-box-blue');
      const tryAgainBoxBluetext = document.getElementById('Try-again-box-blue-text');
      const correctBoxBluetext = document.getElementById('correct-box-blue-text');
      if (homeWiseathlete && correctBoxBlue && tryAgainBoxBlue && photoBoxBlue)
      {  
        homeWiseathlete.addEventListener('mouseover', () => {
            homeWiseathlete.style.cursor = 'pointer';
        });
  
        homeWiseathlete.addEventListener('mouseout', () => {
            homeWiseathlete.style.cursor = 'default';
        });
  
        homeWiseathlete.addEventListener('click', () => {
          navigate('/');
        });

  
        correctBoxBlue.addEventListener('mouseover', () => {
            correctBoxBlue.style.cursor = 'pointer';
        });
  
        correctBoxBlue.addEventListener('mouseout', () => {
            correctBoxBlue.style.cursor = 'default';
        });
        correctBoxBlue.addEventListener('click', onNext);
        
  
        tryAgainBoxBlue.addEventListener('mouseover', () => {
            tryAgainBoxBlue.style.cursor = 'pointer';
        });
  
        tryAgainBoxBlue.addEventListener('mouseout', () => {
            tryAgainBoxBlue.style.cursor = 'default';
        });
        tryAgainBoxBlue.addEventListener('click',onNext);
        

        photoBoxBlue.setAttribute('href',parisSportsImage)


        if(isTrue) {
          tryAgainBoxBlue.style.display = "none";
            correctBoxBluetext.textContent = "Next";
            
        }
        else {
          correctBoxBlue.style.display = "none";
          tryAgainBoxBluetext.textContent = "Try Again";
        }
      }
     
    },[]);
  return (
    <div className="App-header-AfterBlue">
      <BluePageAfterSVG/>
      <div className="question-discription-blue-div">
          <p className="question-discription-blue">{feedback}</p>
      </div>
    </div>
  );
}

export default BluePageAfter;