import {React, useEffect} from 'react';
import '../styles/FirstPage.css';
import {ReactComponent as FirstPageSVG } from '../assets/SVG/FirstPage.svg'
import { useNavigate } from 'react-router-dom';

const FirstPage = () => {

  const navigate = useNavigate();

  useEffect(() => {
    // Blue Region 5
    const blue_circle = document.getElementById('blue_circle');
    const blue_text = document.getElementById('blue_text');

    blue_circle.addEventListener('mouseover', () => {
      blue_circle.style.cursor = 'pointer';
    });

    blue_circle.addEventListener('mouseout', () => {
        blue_circle.style.cursor = 'default';
    });

    blue_text.addEventListener('mouseover', () => {
      blue_text.style.cursor = 'pointer';
    });

    blue_text.addEventListener('mouseout', () => {
      blue_text.style.cursor = 'default';
    });

    blue_circle.addEventListener('click', () => {
      navigate('/5');
    });

    blue_text.addEventListener('click', () => {
      navigate('/5');
    });

    // RED Region 1
    const red_circle = document.getElementById('red_circle');
    const red_text = document.getElementById('red_text');

    red_circle.addEventListener('mouseover', () => {
      red_circle.style.cursor = 'pointer';
    });

    red_circle.addEventListener('mouseout', () => {
      red_circle.style.cursor = 'default';
    });

    red_text.addEventListener('mouseover', () => {
      red_text.style.cursor = 'pointer';
    });

    red_text.addEventListener('mouseout', () => {
      red_text.style.cursor = 'default';
    });

    red_circle.addEventListener('click', () => {
      navigate('/1');
    });

    red_text.addEventListener('click', () => {
      navigate('/1');
    });

    // GREEN Region 2
    const green_circle = document.getElementById('green_circle');
    const green_text = document.getElementById('green_text');

    green_circle.addEventListener('mouseover', () => {
      green_circle.style.cursor = 'pointer';
    });

    green_circle.addEventListener('mouseout', () => {
      green_circle.style.cursor = 'default';
    });

    green_text.addEventListener('mouseover', () => {
      green_text.style.cursor = 'pointer';
    });

    green_text.addEventListener('mouseout', () => {
      green_text.style.cursor = 'default';
    });

    green_circle.addEventListener('click', () => {
      navigate('/2');
    });

    green_text.addEventListener('click', () => {
      navigate('/2');
    });

    //BLACK Region 3
    const black_circle = document.getElementById('black_circle');
    const black_text = document.getElementById('black_text');

    black_circle.addEventListener('mouseover', () => {
      black_circle.style.cursor = 'pointer';
    });

    black_circle.addEventListener('mouseout', () => {
      black_circle.style.cursor = 'default';
    });

    black_text.addEventListener('mouseover', () => {
      black_text.style.cursor = 'pointer';
    });

    black_text.addEventListener('mouseout', () => {
      black_text.style.cursor = 'default';
    });

    black_circle.addEventListener('click', () => {
      navigate('/3');
    });

    black_text.addEventListener('click', () => {
      navigate('/3');
    });

    //YELLOW Region 4
    const yellow_circle = document.getElementById('yellow_circle');
    const yellow_text = document.getElementById('yellow_text');

    yellow_circle.addEventListener('mouseover', () => {
      yellow_circle.style.cursor = 'pointer';
    });

    yellow_circle.addEventListener('mouseout', () => {
      yellow_circle.style.cursor = 'default';
    });

    yellow_text.addEventListener('mouseover', () => {
      yellow_text.style.cursor = 'pointer';
    });

    yellow_text.addEventListener('mouseout', () => {
      yellow_text.style.cursor = 'default';
    });

    yellow_circle.addEventListener('click', () => {
      navigate('/4');
    });

    yellow_text.addEventListener('click', () => {
      navigate('/4');
    });
  }, []);

  return (
    <div className="App-header">
      <FirstPageSVG />
    </div>
  );
}

export default FirstPage;
