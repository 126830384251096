import {React, useEffect} from 'react';
import {parisSportsImage} from '../../assets';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BlackPageAfterSVG } from '../../assets/SVG/BlackPageAfter.svg';

const BlackPageAfter = ({ feedback, onNext, isTrue }) => {
  const navigate = useNavigate();
    useEffect(() => {
      const homeWiseathlete = document.getElementById('home_wiseathlete');
      const correctBoxBlack = document.getElementById('correct-box-black');
      const tryAgainBoxBlack = document.getElementById('Try-again-box-black');
      const photoBoxBlack = document.getElementById('photo-box-black');
      const tryAgainBoxBlacktext = document.getElementById('Try-again-box-black-text');
      const correctBoxBlacktext = document.getElementById('correct-box-black-text');
      if (homeWiseathlete && correctBoxBlack && tryAgainBoxBlack && photoBoxBlack)
      {  
        homeWiseathlete.addEventListener('mouseover', () => {
            homeWiseathlete.style.cursor = 'pointer';
        });
  
        homeWiseathlete.addEventListener('mouseout', () => {
            homeWiseathlete.style.cursor = 'default';
        });
  
        homeWiseathlete.addEventListener('click', () => {
          navigate('/');
        });

  
        correctBoxBlack.addEventListener('mouseover', () => {
            correctBoxBlack.style.cursor = 'pointer';
        });
  
        correctBoxBlack.addEventListener('mouseout', () => {
            correctBoxBlack.style.cursor = 'default';
        });
        correctBoxBlack.addEventListener('click', onNext);
        
  
        tryAgainBoxBlack.addEventListener('mouseover', () => {
            tryAgainBoxBlack.style.cursor = 'pointer';
        });
  
        tryAgainBoxBlack.addEventListener('mouseout', () => {
            tryAgainBoxBlack.style.cursor = 'default';
        });
        tryAgainBoxBlack.addEventListener('click',onNext);
        

        photoBoxBlack.setAttribute('href',parisSportsImage)


        if(isTrue) {
          tryAgainBoxBlack.style.display = "none";
            correctBoxBlacktext.textContent = "Next";
            
        }
        else {
          correctBoxBlack.style.display = "none";
          tryAgainBoxBlacktext.textContent = "Try Again";
        }
      }
     
    },[]);
  return (
    <div className="App-header-AfterBlack">
      <BlackPageAfterSVG/>
      <div className="question-discription-black-div">
          <p className="question-discription-black">{feedback}</p>
      </div>
    </div>
  );
}

export default BlackPageAfter;