import { useEffect, useState } from 'react';
import { ReactComponent as GreenPageSVG } from '../assets/SVG/GreenPage.svg';
import { useNavigate } from 'react-router-dom';
import data from '../assets/question-answer.json'; 
import GreenPageAfter from './AfterPages/GreenPageAfter';
import '../styles/Layout.css';

const GreenPage = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [answerCorrect, setAnswerCorrect] = useState(false);
  const questions = data.green;
  const { questionText, options, information } = questions[currentQuestionIndex];
  const [feedback, setFeedback] = useState('');

  const handleOptionClick = (isCorrect) => {
    if (isCorrect) {
      setFeedback(information);
      setAnswerCorrect(true);
      setShowFeedback(true);
    } else {
      setFeedback(information);
      setAnswerCorrect(false);
      setShowFeedback(true);
    }
  };

  const handleNext = () => {
    if (answerCorrect) {
      setShowFeedback(false);
      setAnswerCorrect(false);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      if (currentQuestionIndex === questions.length - 1) {
        navigate('/');
      }
    } else {
      setShowFeedback(false);
    }
  };

  useEffect(() => {
    const home_wiseathlete = document.getElementById('home_wiseathlete');
    const Option1green = document.getElementById('Option1-green');
    const Option2green = document.getElementById('Option2-green');
    const Option3green = document.getElementById('Option3-green');
    const answerOption1green = document.getElementById('answer-Option1-green');
    const answerOption2green = document.getElementById('answer-Option2-green');
    const answerOption3green = document.getElementById('answer-Option3-green');
    if (home_wiseathlete && Option1green && Option2green && Option3green && answerOption1green && answerOption2green && answerOption3green)
    {  
      home_wiseathlete.addEventListener('mouseover', () => {
        home_wiseathlete.style.cursor = 'pointer';
      });

      home_wiseathlete.addEventListener('mouseout', () => {
        home_wiseathlete.style.cursor = 'default';
      });

      home_wiseathlete.addEventListener('click', () => {
        navigate('/');
      });

      Option1green.addEventListener('mouseover', () => {
        Option1green.style.cursor = 'pointer';
      });

      Option1green.addEventListener('mouseout', () => {
        Option1green.style.cursor = 'default';
      });
      answerOption1green.addEventListener('mouseover',()=>{
        answerOption1green.style.cursor = 'pointer';
      })

      Option2green.addEventListener('mouseover', () => {
        Option2green.style.cursor = 'pointer';
      });

      Option2green.addEventListener('mouseout', () => {
        Option2green.style.cursor = 'default';
      });
      answerOption2green.addEventListener('mouseover',()=>{
        answerOption2green.style.cursor = 'pointer';
      })
      Option3green.addEventListener('mouseover', () => {
        Option3green.style.cursor = 'pointer';
      });

      Option3green.addEventListener('mouseout', () => {
        Option3green.style.cursor = 'default';
      });
      answerOption3green.addEventListener('mouseover',()=>{
        answerOption3green.style.cursor = 'pointer';
      })

      answerOption1green.textContent = options[0].text;
      answerOption2green.textContent = options[1].text;
      answerOption3green.textContent = options[2].text;

      options.forEach((option, index) => {
        document.getElementById(`Option${index + 1}-green`).onclick = () => handleOptionClick(option.isCorrect);
        document.getElementById(`answer-Option${index + 1}-green`).onclick = () => handleOptionClick(option.isCorrect);
      });
    }
   
  }, [currentQuestionIndex, questionText, options, navigate, showFeedback]);

  return (
    <div className="App-header-green">
      {showFeedback ? (
        <GreenPageAfter feedback={feedback} onNext={handleNext} isTrue={answerCorrect}/>
      ) : (
        <>
          <GreenPageSVG />
          <div className="question-text-div-green">
            <p className="question-text-green">{questionText}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default GreenPage;