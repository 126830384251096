import {YellowPage,BlackPage,BluePage,GreenPage,RedPage,FirstPage} from "../components";

export const ROUTES = [
  {
    path: "/",
    element: <FirstPage />,
  },
  {
    path: "/4",
    element: <YellowPage />,
  },
  {
    path: "/2",
    element: <GreenPage />,
  },
  {
    path: "/1",
    element: <RedPage />,
  },
  {
    path: "/5",
    element: <BluePage />,
  },
  {
    path: "/3",
    element: <BlackPage />,
  },
];
